const addContextToEvent = (event: any, contextKey: string) => {
  const templates = window.VRT?.EBA?.defaultTemplates
  const context = templates?.[contextKey as keyof typeof templates]

  if (
    context
    && !event.context.find((ctx: any) =>
      ctx?.schema === context.schema
    )
  ) {
    event.context.push(context)
  }

  return event
}

export {
  addContextToEvent
}
