const parseCacheControl = (field: string) => {
  // eslint-disable-next-line
  const regex = /(?:^|(?:\s*\,\s*))([^\x00-\x20\(\)<>@\,;\:\\"\/\[\]\?\=\{\}\x7F]+)(?:\=(?:([^\x00-\x20\(\)<>@\,;\:\\"\/\[\]\?\=\{\}\x7F]+)|(?:\"((?:[^"\\]|\\.)*)\")))?/g

  const header: Record<string, string | number> = {}
  const err = field.replace(regex, ($0, $1, $2, $3) => {
    const value = $2 || $3
    header[$1] = value ? value.toLowerCase() : true
    return ''
  })

  if (header['max-age']) {
    try {
      const maxAge = parseInt(header['max-age'] as string, 10)

      if (isNaN(maxAge)) {
        return null
      }

      header['max-age'] = maxAge
    }
    catch (error) {
      console.warn({ message: 'parseCacheControl could not parse max-age attribute', error })
    }
  }

  return (err ? null : header)
}

export {
  parseCacheControl
}
