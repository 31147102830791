import AmericanFootball from './icons/american-football/light.svg'
import AmericanFootballAlt from './icons/american-football-alt/light.svg'
import Archery from './icons/archery/light.svg'
import Athletics from './icons/athletics/light.svg'
import Badminton from './icons/badminton/light.svg'
import Baseball from './icons/baseball/light.svg'
import Basketball from './icons/basketball/light.svg'
import Biathlon from './icons/biathlon/light.svg'
import Biljarts from './icons/biljarts/light.svg'
import Bmx from './icons/bmx/light.svg'
import Bobsleigh from './icons/bobsleigh/light.svg'
import Boxing from './icons/boxing/light.svg'
import Breakdance from './icons/breakdance/light.svg'
import CanoeKayak from './icons/canoe-kayak/light.svg'
import CarRacing from './icons/car-racing/light.svg'
import Chess from './icons/chess/light.svg'
import Climbing from './icons/climbing/light.svg'
import Cricket from './icons/cricket/light.svg'
import Curling from './icons/curling/light.svg'
import Cycling from './icons/cycling/light.svg'
import CyclingAlt from './icons/cycling-alt/light.svg'
import CyclingAlt2 from './icons/cycling-alt-2/light.svg'
import CyclingAlt3 from './icons/cycling-alt-3/light.svg'
import Darts from './icons/darts/light.svg'
import Diving from './icons/diving/light.svg'
import Divingboard from './icons/divingboard/light.svg'
import Duathlon from './icons/duathlon/light.svg'
import Esports from './icons/esports/light.svg'
import Fencing from './icons/fencing/light.svg'
import FieldHockey from './icons/field-hockey/light.svg'
import FigureSkating from './icons/figure-skating/light.svg'
import Formula1 from './icons/formula-1/light.svg'
import Golf from './icons/golf/light.svg'
import Gymnastics from './icons/gymnastics/light.svg'
import Handball from './icons/handball/light.svg'
import HorseRacing from './icons/horse-racing/light.svg'
import IceHockey from './icons/ice-hockey/light.svg'
import IceSkating from './icons/ice-skating/light.svg'
import Jetski from './icons/jetski/light.svg'
import Korfbal from './icons/korfbal/light.svg'
import MartialArts from './icons/martial-arts/light.svg'
import MotoGp from './icons/moto-gp/light.svg'
import Motorcross from './icons/motorcross/light.svg'
import Mountainbike from './icons/mountainbike/light.svg'
import MountainbikeAlt from './icons/mountainbike-alt/light.svg'
import Olympics from './icons/olympics/light.svg'
import Padel from './icons/padel/light.svg'
import Rodeling from './icons/rodeling/light.svg'
import Rowing from './icons/rowing/light.svg'
import Rugby from './icons/rugby/light.svg'
import Running from './icons/running/light.svg'
import Sailing from './icons/sailing/light.svg'
import Shooting from './icons/shooting/light.svg'
import Skateboarding from './icons/skateboarding/light.svg'
import Skeelering from './icons/skeelering/light.svg'
import Skeleton from './icons/skeleton/light.svg'
import SkiJumping from './icons/ski-jumping/light.svg'
import Skiing from './icons/skiing/light.svg'
import Snowboarding from './icons/snowboarding/light.svg'
import Soccer from './icons/soccer/light.svg'
import Squach from './icons/squach/light.svg'
import Surfing from './icons/surfing/light.svg'
import Swimming from './icons/swimming/light.svg'
import TableTennis from './icons/table-tennis/light.svg'
import Tennis from './icons/tennis/light.svg'
import Triathlon from './icons/triathlon/light.svg'
import Volleyball from './icons/volleyball/light.svg'
import Waterpolo from './icons/waterpolo/light.svg'
import WeightLifting from './icons/weight-lifting/light.svg'
import Wrestling from './icons/wrestling/light.svg'

export type IconNameSports = keyof typeof iconsSports;

export const iconsSports = {
  'sport-american-football': AmericanFootball,
  'sport-american-football-alt': AmericanFootballAlt,
  'sport-archery': Archery,
  'sport-athletics': Athletics,
  'sport-badminton': Badminton,
  'sport-baseball': Baseball,
  'sport-basketball': Basketball,
  'sport-biathlon': Biathlon,
  'sport-biljarts': Biljarts,
  'sport-bmx': Bmx,
  'sport-bobsleigh': Bobsleigh,
  'sport-boxing': Boxing,
  'sport-breakdance': Breakdance,
  'sport-canoe-kayak': CanoeKayak,
  'sport-car-racing': CarRacing,
  'sport-chess': Chess,
  'sport-climbing': Climbing,
  'sport-cricket': Cricket,
  'sport-curling': Curling,
  'sport-cycling': Cycling,
  'sport-cycling-alt': CyclingAlt,
  'sport-cycling-alt-2': CyclingAlt2,
  'sport-cycling-alt-3': CyclingAlt3,
  'sport-darts': Darts,
  'sport-diving': Diving,
  'sport-divingboard': Divingboard,
  'sport-duathlon': Duathlon,
  'sport-esports': Esports,
  'sport-fencing': Fencing,
  'sport-field-hockey': FieldHockey,
  'sport-hockey': FieldHockey,
  'sport-figure-skating': FigureSkating,
  'sport-formula1': Formula1,
  'sport-golf': Golf,
  'sport-gymnastics': Gymnastics,
  'sport-handball': Handball,
  'sport-horse-racing': HorseRacing,
  'sport-ice-hockey': IceHockey,
  'sport-ice-skating': IceSkating,
  'sport-jetski': Jetski,
  'sport-korfbal': Korfbal,
  'sport-martial-arts': MartialArts,
  'sport-moto-gp': MotoGp,
  'sport-motorcross': Motorcross,
  'sport-mountainbike': Mountainbike,
  'sport-mountainbike-alt': MountainbikeAlt,
  'sport-olympics': Olympics,
  'sport-padel': Padel,
  'sport-rodeling': Rodeling,
  'sport-rowing': Rowing,
  'sport-rugby': Rugby,
  'sport-running': Running,
  'sport-sailing': Sailing,
  'sport-shooting': Shooting,
  'sport-skateboarding': Skateboarding,
  'sport-skeelering': Skeelering,
  'sport-skeleton': Skeleton,
  'sport-ski-jumping': SkiJumping,
  'sport-skiing': Skiing,
  'sport-snowboarding': Snowboarding,
  'sport-soccer': Soccer,
  'sport-squach': Squach,
  'sport-surfing': Surfing,
  'sport-swimming': Swimming,
  'sport-table-tennis': TableTennis,
  'sport-tennis': Tennis,
  'sport-triathlon': Triathlon,
  'sport-volleyball': Volleyball,
  'sport-waterpolo': Waterpolo,
  'sport-weight-lifting': WeightLifting,
  'sport-wrestling': Wrestling
}
