import type { RefObject } from 'react'
import type { LinkClickArguments } from '../events'

import { isObjectWithData } from '@sporza/utils/objects'
import { useEffect } from 'react'

import { linkClickEvent } from '../events'

interface UseLinkClickArguments extends LinkClickArguments {
  ref: RefObject<Element>,
  disableTracking?: boolean
}

const useLinkClick = (
  {
    ref,
    disableTracking = false,
    ...linkClickArgs
  }: UseLinkClickArguments
): void => {
  if (disableTracking) {
    return
  }

  const callLinkClickEvent = () => {
    linkClickEvent(linkClickArgs)
  }

  useEffect(() => {
    if (ref && isObjectWithData(linkClickArgs))
      ref.current
        ?.addEventListener('click', callLinkClickEvent)

    return () => {
      if (ref && isObjectWithData(linkClickArgs))
        ref.current
          ?.removeEventListener('click', callLinkClickEvent)
    }
  }, [ref])
}

export {
  useLinkClick
}
