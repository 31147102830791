import type { CSSProperties, FunctionComponent, PropsWithChildren, RefObject } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import { useLinkClick } from '@sporza/tracking'
import clsx from 'clsx'
import { Children, useRef } from 'react'

import Icon from '../../atoms/icon'
import Image, { ImageLayout } from '../image'

import styles from './button.module.scss'

enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  live = 'live',
  list = 'list'
}

enum ButtonSize {
  meta = 'meta',
  small = 'small',
  medium = 'medium',
  large = 'large'
}

interface ButtonProps extends PropsWithChildren {
  id?: string
  title?: string
  size?: string | ButtonSize
  iconBefore?: Icons
  iconAfter?: Icons
  textAfter?: string
  imageBefore?: string
  imageBeforeNoPlaceholder?: boolean
  imageBeforePlaceholder?: string
  className?: string
  darkMode?: boolean
  withHover?: boolean
  withBorder?: boolean
  variant?: string | ButtonVariant
  active?: boolean
  disabled?: boolean
  href?: string
  target?: HTMLAnchorElement['target']
  rel?: HTMLAnchorElement['rel']
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  onMouseEnter?: () => void
  withIconBackground?: boolean
  value?: string
  tabIndex?: number,
  ebaData?: any
  disableLinkTracking?: boolean,
  ariaLabel?: string
  ariaExpanded?: string
  innerRef?: RefObject<HTMLElement>
  style?: CSSProperties
}

const buttonStyles: { [Properties in ButtonVariant]: string } = {
  primary: styles.buttonPrimary,
  secondary: styles.buttonSecondary,
  tertiary: styles.buttonTertiary,
  live: styles.buttonLive,
  list: styles.buttonList
}

const buttonSizes: { [Properties in ButtonSize]: string } = {
  meta: styles.buttonMeta,
  small: styles.buttonSmall,
  medium: styles.buttonMedium,
  large: styles.buttonLarge
}

const filterRest = (rest: Record<string, any>) => Object.keys(rest)
  .reduce((acc, key) => {
    const isValidProp =
      /^aria-/.test(key) ||
      /^data-/.test(key) ||
      /^(id|role|name|title)$/.test(key)

    if (isValidProp) {
      acc[key] = rest[key]
    }

    return acc
  }, {} as Record<string, any>)

const Button: FunctionComponent<ButtonProps> = (
  {
    id,
    title,
    size = ButtonSize.medium,
    iconBefore,
    iconAfter,
    textAfter,
    imageBefore,
    imageBeforeNoPlaceholder,
    imageBeforePlaceholder,
    className,
    variant,
    darkMode,
    withHover = true,
    withBorder = false,
    children,
    disabled,
    active = false,
    href,
    target,
    rel,
    onClick,
    onMouseEnter,
    withIconBackground = false,
    tabIndex,
    ebaData,
    disableLinkTracking = true,
    ariaLabel,
    ariaExpanded,
    innerRef,
    style,
    ...rest
  }
) => {
  const ref = useRef(innerRef || null)
  const ButtonTagName = href ? 'a' : 'button'
  const filteredRest = filterRest(rest)

  useLinkClick({
    ref,
    disableTracking: disableLinkTracking,
    ...ebaData
  })

  return <ButtonTagName
    id={id}
    ref={ref}
    aria-label={ariaLabel}
    aria-expanded={ariaExpanded}
    title={title}
    className={clsx(
      variant ? buttonStyles[variant as ButtonVariant] : buttonStyles.primary,
      buttonSizes[size as ButtonSize],
      withHover && styles.buttonWithHover,
      withBorder && styles.buttonWithBorder,
      active && styles.buttonActive,
      darkMode && styles.buttonDarkMode,
      iconBefore && styles.buttonHasIconBefore,
      iconAfter && styles.buttonHasIconAfter,
      imageBefore && styles.buttonHasImageBefore,
      imageBeforeNoPlaceholder && styles.buttonHasImageBeforeNoPlaceholder,
      href && styles.buttonIsLink,
      !title && !children && styles.buttonIsIconOnly,
      !onClick && !href && styles.buttonWithoutAction,
      className
    )}
    href={href}
    target={target}
    rel={rel}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    disabled={disabled}
    tabIndex={tabIndex}
    style={style}
    {...filteredRest}
  >
    {iconBefore && <Icon name={iconBefore} className={styles.buttonIconBefore} withBackground={withIconBackground}/>}
    {imageBefore && <Image noPlaceholder={imageBeforeNoPlaceholder} layout={ImageLayout.Square} src={imageBefore}
                           fallbackImage={imageBeforePlaceholder} className={styles.buttonImageBefore}/>}
    {
      children
      && <div className={styles.buttonInner}>
        {Children.map(children, child => <span>{child}</span>)}
      </div>
    }
    {textAfter && <span className={styles.buttonTextAfter}>{textAfter}</span>}
    {iconAfter && <Icon name={iconAfter} className={styles.buttonIconAfter} withBackground={withIconBackground}/>}
  </ButtonTagName>
}

export default Button

export {
  ButtonVariant,
  ButtonSize
}

export type {
  ButtonProps
}
