enum ImageLayout {
  Widescreen = 'Widescreen',
  Square = 'Square',
  Portrait = 'Portrait',
  Portrait9x16 = 'Portrait9x16'
}

enum ImageFocalPoint {
  Tl = 'Tl',
  Tm = 'Tm',
  Tr = 'Tr',
  Ml = 'Ml',
  Mm = 'Mm',
  Mr = 'Mr',
  Bl = 'Bl',
  Bm = 'Bm',
  Br = 'Br'
}

export {
  ImageLayout,
  ImageFocalPoint
}
