import Canvas from './icons/canvas.svg'
import Facebook from './icons/facebook.svg'
import FacebookSolid from './icons/facebook-solid.svg'
import Instagram from './icons/instagram.svg'
import InstagramColor from './icons/instagram-color.svg'
import Ketnet from './icons/ketnet.svg'
import KetnetDark from './icons/ketnet-dark.svg'
import Play6 from './icons/play-6.svg'
import Play6Big from './icons/play-6-big.svg'
import Play6MonoBig from './icons/play-6-mono-big.svg'
import PlaySports from './icons/play-sports.svg'
import PlaySportsBig from './icons/play-sports-big.svg'
import Radio1 from './icons/radio-1.svg'
import SporzaLivestream from './icons/sporza-livestream.svg'
import SporzaLivestreamAlt from './icons/sporza-livestream-alt.svg'
import SporzaLivestreamAltDark from './icons/sporza-livestream-alt-dark.svg'
import TikTok from './icons/tik-tok.svg'
import TikTokSolid from './icons/tik-tok-solid.svg'
import Twitter from './icons/twitter.svg'
import Vrt from './icons/vrt.svg'
import Vrt1 from './icons/vrt-1.svg'
import Vrt1Dark from './icons/vrt-1-dark.svg'
import VrtMax from './icons/vrt-max.svg'
import VrtMaxDark from './icons/vrt-max-dark.svg'
import VrtMaxSolid from './icons/vrt-max-solid.svg'
import VrtNu from './icons/vrt-nu.svg'
import VrtNws from './icons/vrt-nws.svg'
import VrtNwsDark from './icons/vrt-nws-dark.svg'
import Whatsapp from './icons/whatsapp.svg'
import X from './icons/x.svg'

export type IconNameChannels = keyof typeof iconsChannels;

export const iconsChannels = {
  'channels-canvas': Canvas,
  'channels-facebook': Facebook,
  'channels-facebook-solid': FacebookSolid,
  'channels-instagram': Instagram,
  'channels-instagram-color': InstagramColor,
  'channels-ketnet': Ketnet,
  'channels-ketnet-dark': KetnetDark,
  'channels-play-6': Play6,
  'channels-play-6-big': Play6Big,
  'channels-play-6-mono-big': Play6MonoBig,
  'channels-play-sports': PlaySports,
  'channels-play-sports-big': PlaySportsBig,
  'channels-radio-1': Radio1,
  'channels-sporza-livestream': SporzaLivestream,
  'channels-sporza-livestream-alt': SporzaLivestreamAlt,
  'channels-sporza-livestream-alt-dark': SporzaLivestreamAltDark,
  'channels-tik-tok': TikTok,
  'channels-tik-tok-solid': TikTokSolid,
  'channels-twitter': Twitter,
  'channels-vrt': Vrt,
  'channels-vrt-1': Vrt1,
  'channels-vrt-1-dark': Vrt1Dark,
  'channels-vrt-max': VrtMax,
  'channels-vrt-max-dark': VrtMaxDark,
  'channels-vrt-max-solid': VrtMaxSolid,
  'channels-vrt-nu': VrtNu,
  'channels-vrt-nws': VrtNws,
  'channels-vrt-nws-dark': VrtNwsDark,
  'channels-whatsapp': Whatsapp,
  'channels-x': X,
}
