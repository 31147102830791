enum Breakpoints {
  small = 0,
  medium = 640,
  large = 768,
  xlarge = 1024,
  xxlarge = 1440
}

export {
  Breakpoints
}
