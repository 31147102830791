import { Sport } from './sport'

enum Environment {
  prod = 'prod',
  stag = 'stag',
  dev = 'dev'
}

enum SporzaAEMHost {
  prod = 'aem.vrt.be',
  stag = 'aem-stag.vrt.be',
  dev = 'aem-dev.vrt.be',
  local = 'aem-dev.vrt.be'
}

enum SporzaPublicSiteHost {
  prod = 'sporza.be',
  stag = 'stag.sporza.be',
  dev = 'dev.sporza.be'
}

enum SporzaPublicAPIBaseUrl {
  prod = 'https://api.sporza.be',
  stag = 'https://sporza-api.stag.a51.be',
  dev = 'https://sporza-api.dev.a51.be'
}

enum SporzaPrivateAPIBaseUrl {
  prod = 'https://sporza-sportservice-varnish.prod.a51.be',
  stag = 'https://sporza-sportservice-varnish.stag.a51.be',
  dev = 'https://sporza-sportservice-varnish.dev.a51.be'
}

enum SporzaNewsroomBaseUrl {
  prod = 'https://newsroom.sporza.be',
  stag = 'https://newsroom-stag.sporza.be',
  dev = 'https://newsroom-dev.sporza.be',
}

enum SporzaNewsroomV2Host {
  prod = 'newsroom-2.sporza.be',
  stag = 'newsroom-2.stag.sporza.be',
  dev = 'newsroom-2.dev.sporza.be',
  local = 'localhost'
}

enum SporzaDesignSystemBaseUrl {
  prod = 'https://design-system.sporza.be',
  stag = 'https://design-system.sporza.stag.a51.be',
  dev = 'https://design-system.sporza.dev.a51.be'
}

enum SporzaComponentServiceBaseUrl {
  prod = 'http://sporza-web-2-alb-prod-eu-west-1.prod.a51.be',
  stag = 'http://sporza-web-2-alb-stag-eu-west-1.stag.a51.be',
  dev = 'http://sporza-web-2-alb-dev-eu-west-1.dev.a51.be'
}

enum VRTBrand {
  VRT = 'VRT',
  VRTNWS = 'VRTNWS',
  VRTMAX = 'VRTMAX'
}

enum VRTBrandHosts {
  VRT = 'vrt.be',
  VRTNWS = 'vrtnws.be',
  VRTMAX = 'vrtmax.be',
  VRTNU = 'vrtnu.be'
}

export const searchBaseUrl = typeof process !== 'undefined' && process?.env?.ELASTICSEARCH_BASE_URL || 'https://search-es.prod.a51.be'
export const apiBaseUrl = typeof process !== 'undefined' && process?.env?.API_BASE_URL || 'https://sporza-api.dev.a51.be'
export const adminApiBaseUrl = typeof process !== 'undefined' && process?.env?.ADMIN_API_BASE_URL || 'https://sportservice-admin.stag.a51.be'
export const imagestoreBaseUrl = typeof process !== 'undefined' && process?.env?.IMAGESTORE_BASE_URL || 'https://services-admin-stag.vrt.be/imagestore/secure/admin/images'
export const imagestoreUser = typeof process !== 'undefined' && process?.env?.IMAGESTORE_USER || 'aemimages-stag'
export const imagestorePassword = typeof process !== 'undefined' && process?.env?.IMAGESTORE_PASSWORD || 'ThWNS6UOy3CMQ'
export const bffBaseUrl = typeof process !== 'undefined' && process?.env?.BFF_BASE_URL || 'https://sporza-api.dev.a51.be/web'
export const internalBffBaseUrl = typeof process !== 'undefined' && process?.env?.INTERNAL_BFF_BASE_URL || 'https://sporza-api.dev.a51.be/web'
export const internalBffApiKey = typeof process !== 'undefined' && process?.env?.INTERNAL_BFF_APi_KEY || ''
export const designSystemBaseUrl = typeof process !== 'undefined' && process?.env?.DESIGN_SYSTEM_BASE_URL || 'http://localhost:6006'
export const mockServiceBaseUrl = typeof process !== 'undefined' && process?.env?.MOCK_SERVICE_BASE_URL || 'http://localhost:4004'
export const cyclingProfileNewsroomUrl = typeof process !== 'undefined' && process?.env?.CYCLING_PROFILE_NEWSROOM_URL || 'https://cycling-profiles.newsroom-stag.sporza.be/editions'
export const interactieApiBaseUrl = typeof process !== 'undefined' && process?.env?.INTERACTIE_API_BASE_URL || 'https://interactie-admin-stag.vrt.be/voting-api'
export const interactieApiOrigin = typeof process !== 'undefined' && process?.env?.INTERACTIE_API_ORIGIN || 'https://stag.sporza.be'

// Build - environment variable injector, look in esbuild config for `define` property
let buildNr: string | undefined

if (typeof process !== 'undefined' && process?.env) {
  buildNr = process.env.BUILD_NR !== 'undefined' ? process.env.BUILD_NR : ''
}

export const logLevel = typeof process !== 'undefined' && process?.env?.LOG_LEVEL || 'error'
export const nodeEnv = typeof process !== 'undefined' && process?.env?.NODE_ENV || 'development'
export const environment = typeof process !== 'undefined' && process?.env?.ENVIRONMENT || 'dev'
export const awsLambdaFunctionName = typeof process !== 'undefined' && process?.env?.AWS_LAMBDA_FUNCTION_NAME || 'unknown'
export const awsLambdaFunctionVersion = typeof process !== 'undefined' && process?.env?.AWS_LAMBDA_FUNCTION_VERSION || 'unknown'

export {
  Environment,
  SporzaAEMHost,
  SporzaNewsroomBaseUrl,
  SporzaNewsroomV2Host,
  SporzaPublicSiteHost,
  SporzaPublicAPIBaseUrl,
  SporzaPrivateAPIBaseUrl,
  SporzaDesignSystemBaseUrl,
  SporzaComponentServiceBaseUrl,
  VRTBrand,
  VRTBrandHosts,
  buildNr,
  Sport
}
