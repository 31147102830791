import BlueCard from './icons/blue-card.svg'
import CounterGoal from './icons/counter-goal.svg'
import FastestLap from './icons/fastest-lap.svg'
import GreenCard from './icons/green-card.svg'
import GreenFlag from './icons/green-flag.svg'
import HandballGoal from './icons/handball-goal.svg'
import HandballGoalPost from './icons/handball-goal-post.svg'
import HandballSave from './icons/handball-save.svg'
import HockeyGoal from './icons/hockey-goal.svg'
import HockeyGoalPost from './icons/hockey-goal-post.svg'
import HockeySave from './icons/hockey-save.svg'
import Miss from './icons/miss.svg'
import PenaltyBall from './icons/penalty-ball.svg'
import PenaltyCorner from './icons/penalty-corner.svg'
import PenaltyThrow from './icons/penalty-throw.svg'
import PenaltyThrowGoal from './icons/penalty-throw-goal.svg'
import PenaltyTime from './icons/penalty-time.svg'
import TimePenalty from './icons/penalty-time.svg'
import RedAfterYellowCard from './icons/red-after-yellow.svg'
import RedCard from './icons/red-card.svg'
import RedFlag from './icons/red-flag.svg'
import WeatherChange from './icons/weather-change.svg'
import YellowCard from './icons/yellow-card.svg'
import YellowFlag from './icons/yellow-flag.svg'

export type IconNameGeneric = keyof typeof iconsGeneric;

export const iconsGeneric = {
  'generic-blue-card': BlueCard,
  'generic-counter-goal': CounterGoal,
  'generic-fastest-lap': FastestLap,
  'generic-green-card': GreenCard,
  'generic-green-flag': GreenFlag,
  'generic-handball-goal': HandballGoal,
  'generic-handball-goal-post': HandballGoalPost,
  'generic-handball-save': HandballSave,
  'generic-hockey-goal': HockeyGoal,
  'generic-hockey-goal-post': HockeyGoalPost,
  'generic-hockey-save': HockeySave,
  'generic-miss': Miss,
  'generic-penalty-ball': PenaltyBall,
  'generic-penalty-corner': PenaltyCorner,
  'generic-penalty-throw': PenaltyThrow,
  'generic-penalty-throw-goal': PenaltyThrowGoal,
  'generic-penalty-time': PenaltyTime,
  'generic-red-after-yellow-card': RedAfterYellowCard,
  'generic-red-card': RedCard,
  'generic-red-flag': RedFlag,
  'generic-time-penalty': TimePenalty,
  'generic-weather-change': WeatherChange,
  'generic-yellow-card': YellowCard,
  'generic-yellow-flag': YellowFlag,
}
