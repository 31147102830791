import ExtendedTime from './icons/+15-extended-time.svg'
import ExtendedTimeDark from './icons/+15-extended-time-dark.svg'
import Assist from './icons/assist.svg'
import Birthday from './icons/birthday.svg'
import Blooper from './icons/blooper.svg'
import BlooperDark from './icons/blooper-dark.svg'
import Champion from './icons/champion.svg'
import ChampionsLeague from './icons/champions-league.svg'
import CornerGoal from './icons/corner-goal.svg'
import CornerGoalAlt from './icons/corner-goal-alt.svg'
import CornerGoalAltDark from './icons/corner-goal-alt-dark.svg'
import CornerGoalDark from './icons/corner-goal-dark.svg'
import DissalowedGoal from './icons/dissalowed-goal.svg'
import EndWhistle from './icons/end-whistle.svg'
import EndWhistleDark from './icons/end-whistle-dark.svg'
import EuropaLeague from './icons/europa-league.svg'
import ExtraTime from './icons/extra-time.svg'
import FreeKickGoal from './icons/free-kick-goal.svg'
import FreeKickGoalDark from './icons/free-kick-goal-dark.svg'
import Goal from './icons/goal.svg'
import Goalpost from './icons/goalpost.svg'
import HalftimeWhistle from './icons/halftime-whistle.svg'
import HalftimeWhistleDark from './icons/halftime-whistle-dark.svg'
import Hands from './icons/hands.svg'
import HandsDark from './icons/hands-dark.svg'
import Injury from './icons/injury.svg'
import Interruption from './icons/interruption.svg'
import Offside from './icons/offside.svg'
import Overtime from './icons/overtime.svg'
import OvertimeDark from './icons/overtime-dark.svg'
import OwnGoal from './icons/own-goal.svg'
import PenaltyGoal from './icons/penalty-goal.svg'
import PenaltyGoalDark from './icons/penalty-goal-dark.svg'
import PenaltyMissed from './icons/penalty-missed.svg'
import PlayerIn from './icons/player-in.svg'
import PlayerInDark from './icons/player-in-dark.svg'
import PlayerOut from './icons/player-out.svg'
import RedAfterYellow from './icons/red-after-yellow.svg'
import RedCard from './icons/red-card.svg'
import Save from './icons/save.svg'
import SaveDark from './icons/save-dark.svg'
import StartWhistle from './icons/start-whistle.svg'
import StartWhistleDark from './icons/start-whistle-dark.svg'
import Stats from './icons/stats.svg'
import Streaker from './icons/streaker.svg'
import Substitute from './icons/substitute.svg'
import SubstituteDark from './icons/substitute-dark.svg'
import SubstituteMono from './icons/substitute-mono.svg'
import TeamCaptain from './icons/team-captain.svg'
import TeamCaptainDark from './icons/team-captain-dark.svg'
import Var from './icons/var.svg'
import YellowCard from './icons/yellow-card.svg'

export type IconNameSoccer = keyof typeof iconsSoccer;

export const iconsSoccer = {
  'soccer-15-extended-time': ExtendedTime,
  'soccer-15-extended-time-dark': ExtendedTimeDark,
  'soccer-assist': Assist,
  'soccer-birthday': Birthday,
  'soccer-blooper': Blooper,
  'soccer-blooper-dark': BlooperDark,
  'soccer-champion': Champion,
  'soccer-champions-league': ChampionsLeague,
  'soccer-corner-goal': CornerGoal,
  'soccer-corner-goal-alt': CornerGoalAlt,
  'soccer-corner-goal-alt-dark': CornerGoalAltDark,
  'soccer-corner-goal-dark': CornerGoalDark,
  'soccer-dissalowed-goal': DissalowedGoal,
  'soccer-europa-league': EuropaLeague,
  'soccer-end-whistle': EndWhistle,
  'soccer-end-whistle-dark': EndWhistleDark,
  'soccer-extra-time': ExtraTime,
  'soccer-free-kick-goal': FreeKickGoal,
  'soccer-free-kick-goal-dark': FreeKickGoalDark,
  'soccer-goal': Goal,
  'soccer-goalpost': Goalpost,
  'soccer-halftime-whistle': HalftimeWhistle,
  'soccer-halftime-whistle-dark': HalftimeWhistleDark,
  'soccer-hands': Hands,
  'soccer-hands-dark': HandsDark,
  'soccer-injury': Injury,
  'soccer-interruption': Interruption,
  'soccer-offside': Offside,
  'soccer-overtime': Overtime,
  'soccer-overtime-dark': OvertimeDark,
  'soccer-own-goal': OwnGoal,
  'soccer-penalty-goal': PenaltyGoal,
  'soccer-penalty-goal-dark': PenaltyGoalDark,
  'soccer-penalty-missed': PenaltyMissed,
  'soccer-player-in': PlayerIn,
  'soccer-player-in-dark': PlayerInDark,
  'soccer-player-out': PlayerOut,
  'soccer-red-after-yellow': RedAfterYellow,
  'soccer-red-card': RedCard,
  'soccer-save': Save,
  'soccer-save-dark': SaveDark,
  'soccer-start-whistle': StartWhistle,
  'soccer-start-whistle-dark': StartWhistleDark,
  'soccer-stats': Stats,
  'soccer-streaker': Streaker,
  'soccer-substitute': Substitute,
  'soccer-substitute-dark': SubstituteDark,
  'soccer-substitute-mono': SubstituteMono,
  'soccer-team-captain': TeamCaptain,
  'soccer-team-captain-dark': TeamCaptainDark,
  'soccer-var': Var,
  'soccer-yellow-card': YellowCard
}
