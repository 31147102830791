import { VRTBrand } from '@sporza/config'

import CIM from './cim.svg'
import EKWielrennen2024 from './ek-wielrennen-2024.svg'
import Engie from './engie.svg'
import ESporza from './eSporza.svg'
import PlaySports from './play-sports.svg'
import Play6 from './play6.svg'
import Sporza from './sporza.svg'
import SporzaApp from './sporza-app.svg'
import SporzaDark from './sporza-dark.svg'
import SporzaSmall from './sporza-small.svg'
import SporzaSmallDark from './sporza-small-dark.svg'
import SporzaVoetbalApp from './sporza-voetbal-app.svg'
import VRTMAX from './vrt-max.svg'
import VRTMAXDark from './vrt-max-dark.svg'
import VRTMAXHorizontal from './vrt-max-horizontal.svg'
import VRTMAXMono from './vrt-max-mono.svg'
import VRTMAXMonoDark from './vrt-max-mono-dark.svg'
import VRTNWS from './vrt-nws.svg'
import VRTNWSDark from './vrt-nws-dark.svg'
import VRTNWSHorizontal from './vrt-nws-horizontal.svg'
import VRTNU from './vrtnu.svg'
import VRTNUDark from './vrtnu-dark.svg'

type Logos = keyof typeof logos

const logos = {
  'e-sporza': ESporza,
  'engie': Engie,
  'ek-wielrennen-2024': EKWielrennen2024,
  'play-sports': PlaySports,
  'play6': Play6,
  'sporza': Sporza,
  'sporza-dark': SporzaDark,
  'sporza-small': SporzaSmall,
  'sporza-small-dark': SporzaSmallDark,
  'vrtmax': VRTMAX,
  'vrtmax-dark': VRTMAXDark,
  'vrtmax-mono': VRTMAXMono,
  'vrtmax-mono-dark': VRTMAXMonoDark,
  'vrtmax-horizontal': VRTMAXHorizontal,
  'vrt-max': VRTMAX,
  'vrt-max-dark': VRTMAXDark,
  'vrt-max-mono': VRTMAXMono,
  'vrt-max-mono-dark': VRTMAXMonoDark,
  'vrt-max-horizontal': VRTMAXHorizontal,
  'vrtnu': VRTNU,
  'vrtnu-dark': VRTNUDark,
  'vrtnws': VRTNWS,
  'vrtnws-dark': VRTNWSDark,
  'vrtnws-horizontal': VRTNWSHorizontal,
  'vrt-nws': VRTNWS,
  'vrt-nws-dark': VRTNWSDark,
  'vrt-nws-horizontal': VRTNWSHorizontal,
  'cim': CIM,
  'sporza-app': SporzaApp,
  'sporza-voetball-app': SporzaVoetbalApp,
}

const mapBrandToLogo = (brand: string) => {
  switch (brand) {
    case VRTBrand.VRTMAX:
      return 'vrt-max'
    case VRTBrand.VRTNWS:
      return 'vrt-nws'
    default:
      return null
  }
}

export type {
  Logos
}

export {
  logos,
  mapBrandToLogo
}
