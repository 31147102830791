import { addContextToEvent } from '../utils'

const getPageEventData = () => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - cannot get page event data, not running in browser')

    return
  }

  const { page } = window.ebaData || {}
  let {
    context_page = {},
    context_content_article = {},
    context_content_sport = {}
  } = page || {}

  if (context_page.data) {
    const { data: contextPageData } = context_page
    context_page = contextPageData
  }

  if (context_content_article.data) {
    const { data: contextContentArticleData } = context_content_article
    context_content_article = contextContentArticleData
  }

  if (context_content_sport.data) {
    const { data: contextContentSportData } = context_content_sport
    context_content_sport = contextContentSportData
  }

  const eventData = {
    '$pana': context_page.pagename || window.location?.pathname || undefined,
    '$patb': context_page.touchpointbrand || 'sporza',
    '$pacb': context_page.contentbrand || 'sporza',

    '$pas1': context_page.sitesection1,
    '$pas2': context_page.sitesection2,
    '$pas3': context_page.sitesection3,
    '$pas4': context_page.sitesection4,

    '$pato': context_page.topics,
    '$pala': context_page.labels,
    '$paca': context_page.category,
    '$pacms': context_page.cms,
    '$papb': context_page.programbrand,
    '$paid': context_page.pageid,

    '$caat': context_content_article.articletype,
    '$caar': context_content_article.articleregion,
    '$caawc': parseInt(context_content_article.articlewordcount),
    '$caavc': parseInt(context_content_article.articlevideocount),
    '$caaac': parseInt(context_content_article.articleaudiocount),
    '$capts': parseInt(context_content_article.publishtimestamp),
    '$cauts': parseInt(context_content_article.updatetimestamp),

    // CONTEXT sport
    '$cssn': context_content_sport.sportname,
    '$cssc': context_content_sport.sportcategory,
    '$cscn': context_content_sport.competitionname,
    '$cscr': context_content_sport.competitionregion
  }

  return eventData
}

const pageImpressionEvent = () => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - no page impression triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track page impression')

    return
  }

  try {
    const eventData = getPageEventData()
    let pageImpression = { ...templates.page_impression_1_1_0 }
    pageImpression = addContextToEvent(pageImpression, 'context_article')
    pageImpression = addContextToEvent(pageImpression, 'context_content_sport')

    trackUsingTemplate(
      pageImpression,
      eventData
    )
  } catch (e) {
    console.error('failed to send page impression event', e)
  }
}

export {
  pageImpressionEvent,
  getPageEventData
}
