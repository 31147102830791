import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './caption.module.scss'

enum CaptionWeight {
  Medium = 'Medium',
  Bold = 'Bold'
}

interface CaptionProps extends PropsWithChildren {
  weight?: string | CaptionWeight
  className?: string
  darkMode?: boolean
}

const Caption: FunctionComponent<CaptionProps> = (
  {
    weight,
    children,
    className,
    darkMode
  }
) => {
  const TagElement = 'div'

  return <TagElement
    className={clsx(
      styles.caption,
      weight === CaptionWeight.Medium && styles.captionMedium,
      weight === CaptionWeight.Bold && styles.captionBold,
      darkMode && styles.captionDark,
      className
    )}
  >
    {children}
  </TagElement>
}

export default Caption

export {
  CaptionWeight
}

export type {
  CaptionProps
}
