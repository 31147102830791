import type { FunctionComponent } from 'react'
import type { Logos } from './svgs'

import clsx from 'clsx'

import styles from './logo.module.scss'
import { logos } from './svgs'

interface LogoProps {
  logo: Logos
  className?: string
  darkMode?: boolean
  monoTone?: boolean
  link? :string
}

const Logo: FunctionComponent<LogoProps> = (
  {
    logo,
    className,
    darkMode,
    monoTone,
    link
  }) => {
  if (!logo) {
    return null
  }
  const logoKeys = Object.keys(logos)
  const shouldUseDarkModeIcon = darkMode && logoKeys.includes(`${logo}-dark`)
  const shouldUseMonoToneIcon = monoTone
    && logoKeys.includes(`${logo}-mono`)
    && logoKeys.includes(`${logo}-mono-dark`)

  let logoName = logo

  if (shouldUseDarkModeIcon) {
    logoName = `${logo}-dark` as LogoProps['logo']
  }

  if (shouldUseMonoToneIcon) {
    logoName = `${logo}-mono${shouldUseDarkModeIcon ? '-dark' : ''}` as LogoProps['logo']
  }

  const LogoSVG = logos[logoName as LogoProps['logo']]

  const logoReturn = LogoSVG
    ? <i className={clsx(
      styles.logo,
      className,
      monoTone && styles.monoTone
    )}>
      <LogoSVG />
    </i>
    : null

  if (link){
    return <a href={link}>{logoReturn}</a>
  } else {
    return logoReturn
  }
}

export default Logo

export type {
  Logos,
  LogoProps,
}
