const findParent = function (el: Element | HTMLElement | null, cls: string): Element | HTMLElement | null {
  if (el != null) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) ;
  }
  return el
}

type Script = {
  src: string,
  async?: boolean,
  defer?: boolean
  id?: string
}

const addScriptToDOM = ({ src, async, defer, id }: Script) => {
  if (!window?.document?.body) {
    console.warn('no document body found to inject script to')

    return
  }

  const script = document.createElement('script')
  script.src = src
  id ? script.id = id : undefined
  script.async = !!async
  script.defer = !!defer

  document.body.appendChild(script)
}

export {
  findParent,
  addScriptToDOM
}
