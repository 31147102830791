import type { ImageProfile, ImageProps } from './'

import { Breakpoints } from '../../design-tokens/breakpoints'

import { ImageLayout } from './'

const defaultProfiles = [
  {
    name: 'width1280',
    view: '1280w'
  },
  {
    name: 'width960',
    view: '960w'
  },
  {
    name: 'width640',
    view: '640w'
  },
  {
    name: 'width320',
    view: '320w'
  }
]

const defaultNoCropProfiles = [
  {
    name: 'width1280hx',
    view: '1280w'
  },
  {
    name: 'width960hx',
    view: '960w'
  },
  {
    name: 'width640hx',
    view: '640w'
  },
  {
    name: 'width320hx',
    view: '320w'
  }
]

// TODO: add profiles in imagestore admin
const squareProfiles = [
  {
    name: 'native_mobile_200_200',
    view: '200w'
  }, {
    name: 'native_mobile_400_400',
    view: '400w'
  }
]

const portraitProfiles = [
  {
    name: 'native_mobile_800_1200',
    view: '800w'
  },
  {
    name: 'native_mobile_400_600',
    view: '400w'
  }
]

const generateSrcSet = (src: string, profiles: ImageProfile[]) => {
  return Array.isArray(profiles)
    ? profiles
      .map(profile =>
        `${src.replace(/orig/, profile.name)} ${profile.view}`
      )
    : src
}

const generateSizes = (columns = 4) => {
  return `(min-width: ${Breakpoints.xxlarge}px) ${Breakpoints.xxlarge/columns}px,
    (min-width: ${Breakpoints.xlarge}px) ${Breakpoints.xlarge/columns}px,
    (min-width: ${Breakpoints.large}px) ${Breakpoints.large/columns}px,
    (min-width: ${Breakpoints.medium}px) ${Breakpoints.medium/columns}px,
    100vw`
}

const generateDefaultSrc = (src: string, profileName: ImageProfile['name']) => {
  return profileName && src
    ? src.replace(/orig/, profileName)
    : src
}

const getImageProfiles = (layout: ImageProps['layout'], noCrop?: boolean) => {
  switch (true) {
    case noCrop:
      return defaultNoCropProfiles
    case layout === ImageLayout.Portrait:
      return portraitProfiles
    case layout === ImageLayout.Square:
      return squareProfiles
    case layout === ImageLayout.Widescreen:
    default:
      return defaultProfiles
  }
}

export {
  defaultProfiles,
  generateDefaultSrc,
  generateSizes,
  generateSrcSet,
  getImageProfiles,
  portraitProfiles,
  squareProfiles,
}
