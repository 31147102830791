type KeyFromEnumValueFunction<T> = (
  enumList: T,
  value: string
) => T;

const getKeyFromEnumValue: KeyFromEnumValueFunction<any> = (enumList, value) => {
  const indexOfEnumValue = Object
    .values(enumList)
    .indexOf(value as unknown as typeof enumList)

  return Object
    .keys(enumList)[indexOfEnumValue] as keyof typeof enumList
}

const checkNested = function(obj: any, args: string[]) {
  for (let i = 0; i < args.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj || !(obj.hasOwnProperty(args[i]) || typeof obj[args[i]] === 'function')) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

const waitForGlobal = function(
  parentObject: Record<never, never>,
  keyPath: string,
  callback: () => void,
  timeout = 200
) {
  const args = keyPath.split('.')

  if (checkNested(parentObject, args)) {
    callback()
  } else {
    setTimeout(function() {
      waitForGlobal(parentObject, keyPath, callback, timeout * 1.2)
    }, timeout)
  }
}

const waitForGlobalAsync = function(variable: string, max = 0): Promise<any> {
  return new Promise((resolve) => {
    let timeElapsed = 0
    const args = variable.split('.')

    const checkVariable = () => {
      if (max < timeElapsed) {
        resolve(false)
      } else if (checkNested(window, args)) {
        resolve(true)
      } else {
        timeElapsed += 500
        setTimeout(checkVariable, 500)
      }
    }

    checkVariable()
  })
}

const isObjectWithData = (obj: any) =>
  obj &&
  Object.keys(obj).length > 0


export {
  getKeyFromEnumValue,
  waitForGlobal,
  waitForGlobalAsync,
  isObjectWithData
}
