import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type Tracking = {
  digitalData?: Record<string, any>

  ebaData?: Record<string, any>
}

type TrackingState = {
  tracking: Tracking
  initTracking: (config: any) => void
}

const updateDigitalData = (digitalData: any) => {
  if (typeof window === 'undefined')
    return

  window.digitalData = window.digitalData || {}
  window.digitalData = {
    ...window.digitalData,
    ...digitalData
  }
}

// TODO: check if we can ditch this? EBA Data doesn't need to be on Window
const updateEBAData = (ebaData: any) => {
  if (typeof window === 'undefined')
    return

  window.ebaData = window.ebaData || {}
  window.ebaData = {
    ...window.ebaData,
    ...ebaData
  }
}

const useTrackingStore = create<TrackingState>()(
  devtools(
    (set) => ({
      tracking: {},
      initTracking: (tracking) => {
        if (!tracking) {
          return
        }

        set(
          () => ({
            tracking: {
              ...tracking
            }
          }),
          false,
          'initTracking'
        )

        const { ebaData, digitalData } = tracking
        updateEBAData(ebaData)
        updateDigitalData(digitalData)
      },
      clearTracking: () => {
        set(
          () => ({
            tracking: {}
          }),
          false,
          'clearTracking'
        )

        updateEBAData({})
        updateDigitalData({})
      }
    })
  )
)

export {
  useTrackingStore
}

export type {
  TrackingState,
  Tracking
}
