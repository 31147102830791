import { getPageEventData } from './page-impression'

interface LinkClickArguments {
  targetPageUrl?: string
  targetPageName?: string
  targetPageId?: string
  linkTitle: string
  linkType?: string
  componentName?: string
  componentId?: string
}

const linkClickEvent = (
  {
    targetPageUrl,
    targetPageName,
    targetPageId,
    linkTitle,
    linkType,
    componentName,
    componentId,
  }: LinkClickArguments
) => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - no link click triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track link click')

    return
  }

  try {
    const eventData = {
      // target
      '$tapu': targetPageUrl,
      '$tapn': targetPageName,
      '$tapi': targetPageId,

      '$linti': linkTitle,

      // optional
      '$linty': linkType,
      '$lincn': componentName,
      '$linid': componentId,
    }

    const pageEventData = getPageEventData()
    const linkClick = { ...templates.link_click_1_1_0 }

    trackUsingTemplate(
      linkClick,
      {
        ...eventData,
        ...pageEventData
      }
    )
  } catch (e) {
    console.error('failed to send link click event', e)
  }
}

export {
  linkClickEvent
}

export type {
  LinkClickArguments
}
