import Blue from './icons/cyclists/blue.svg'
import BlueDark from './icons/cyclists/blue-dark.svg'
import BlueDots from './icons/cyclists/blue-dots.svg'
import BlueDotsDark from './icons/cyclists/blue-dots-dark.svg'
import Cyclist from './icons/cyclists/cyclist.svg'
import CyclistDark from './icons/cyclists/cyclist-dark.svg'
import Green from './icons/cyclists/green.svg'
import GreenDark from './icons/cyclists/green-dark.svg'
import Pink from './icons/cyclists/pink.svg'
import PinkDark from './icons/cyclists/pink-dark.svg'
import Purple from './icons/cyclists/purple.svg'
import PurpleDark from './icons/cyclists/purple-dark.svg'
import Red from './icons/cyclists/red.svg'
import RedDark from './icons/cyclists/red-dark.svg'
import RedDots from './icons/cyclists/red-dots.svg'
import RedDotsDark from './icons/cyclists/red-dots-dark.svg'
import White from './icons/cyclists/white.svg'
import WhiteDark from './icons/cyclists/white-dark.svg'
import Yellow from './icons/cyclists/yellow.svg'
import YellowDark from './icons/cyclists/yellow-dark.svg'
import Distance from './icons/distance.svg'
import AverageSpeed from './icons/events/average-speed.svg'
import Birthday from './icons/events/birthday.svg'
import Cobbles from './icons/events/cobbles.svg'
import CobblesDark from './icons/events/cobbles-dark.svg'
import Crash from './icons/events/crash.svg'
import Cyclocross from './icons/events/cyclocross.svg'
import Fans from './icons/events/fans.svg'
import FinalKm from './icons/events/final-km.svg'
import Finish from './icons/events/finish.svg'
import Flat from './icons/events/flat.svg'
import FlatTire from './icons/events/flat-tire.svg'
import Gravel from './icons/events/gravel.svg'
import Hills from './icons/events/hills.svg'
import Hotseat from './icons/events/hotseat.svg'
import Injury from './icons/events/injury.svg'
import Km from './icons/events/km.svg'
import Mechanical from './icons/events/mechanical.svg'
import Mountains from './icons/events/mountains.svg'
import PursuitCar from './icons/events/pursuit-car.svg'
import Quit from './icons/events/quit.svg'
import Road from './icons/events/road.svg'
import Rounds from './icons/events/rounds.svg'
import Sprint from './icons/events/sprint.svg'
import Start from './icons/events/start.svg'
import Stopwatch from './icons/events/stopwatch.svg'
import Supplies from './icons/events/supplies.svg'
import JerseyBlue from './icons/jerseys/blue.svg'
import JerseyGreen from './icons/jerseys/green.svg'
import JerseyPink from './icons/jerseys/pink.svg'
import JerseyPurple from './icons/jerseys/purple.svg'
import JerseyRed from './icons/jerseys/red.svg'
import JerseyRedDots from './icons/jerseys/red-dots.svg'
import JerseyWhite from './icons/jerseys/white.svg'
import JerseyYellow from './icons/jerseys/yellow.svg'
import Cloudy from './icons/weather/cloudy.svg'
import Dry from './icons/weather/dry.svg'
import Extreme from './icons/weather/extreme.svg'
import Fog from './icons/weather/fog.svg'
import Hail from './icons/weather/hail.svg'
import Rain from './icons/weather/rain.svg'
import Snow from './icons/weather/snow.svg'
import Sun from './icons/weather/sun.svg'
import Wind from './icons/weather/wind.svg'

export type IconNameCyclingEvents = keyof typeof iconsCyclingEvents;

export const iconsCyclingEvents = {
  'cycling-average-speed': AverageSpeed,
  'cycling-birthday': Birthday,
  'cycling-cobbles': Cobbles,
  'cycling-cobbles-dark': CobblesDark,
  'cycling-crash': Crash,
  'cycling-cyclocross': Cyclocross,
  'cycling-fans': Fans,
  'cycling-final-km': FinalKm,
  'cycling-finish': Finish,
  'cycling-flat': Flat,
  'cycling-flat-tire': FlatTire,
  'cycling-gravel': Gravel,
  'cycling-hills': Hills,
  'cycling-hotseat': Hotseat,
  'cycling-injury': Injury,
  'cycling-km': Km,
  'cycling-mechanical': Mechanical,
  'cycling-mountains': Mountains,
  'cycling-pursuit-car': PursuitCar,
  'cycling-quit': Quit,
  'cycling-road': Road,
  'cycling-rounds': Rounds,
  'cycling-sprint': Sprint,
  'cycling-start': Start,
  'cycling-stopwatch': Stopwatch,
  'cycling-supplies': Supplies
}

export type IconNameCyclingCyclists = keyof typeof iconsCyclingCyclists;

export const iconsCyclingCyclists = {
  'cycling-blue': Blue,
  'cycling-blue-dark': BlueDark,
  'cycling-blue-dots': BlueDots,
  'cycling-blue-dots-dark': BlueDotsDark,
  'cycling-cyclist': Cyclist,
  'cycling-cyclist-dark': CyclistDark,
  'cycling-green': Green,
  'cycling-green-dark': GreenDark,
  'cycling-pink': Pink,
  'cycling-pink-dark': PinkDark,
  'cycling-purple': Purple,
  'cycling-purple-dark': PurpleDark,
  'cycling-red': Red,
  'cycling-red-dark': RedDark,
  'cycling-red-dots': RedDots,
  'cycling-red-dots-dark': RedDotsDark,
  'cycling-white': White,
  'cycling-white-dark': WhiteDark,
  'cycling-yellow': Yellow,
  'cycling-yellow-dark': YellowDark
}

export type IconNameCyclingJerseys = keyof typeof iconsCyclingJerseys;

export const iconsCyclingJerseys = {
  'cycling-jersey-blue': JerseyBlue,
  'cycling-jersey-green': JerseyGreen,
  'cycling-jersey-pink': JerseyPink,
  'cycling-jersey-purple': JerseyPurple,
  'cycling-jersey-red': JerseyRed,
  'cycling-jersey-red-dots': JerseyRedDots,
  'cycling-jersey-white': JerseyWhite,
  'cycling-jersey-yellow': JerseyYellow,
  'cycling-jersey-blue-dark': JerseyBlue,
  'cycling-jersey-green-dark': JerseyGreen,
  'cycling-jersey-pink-dark': JerseyPink,
  'cycling-jersey-purple-dark': JerseyPurple,
  'cycling-jersey-red-dark': JerseyRed,
  'cycling-jersey-red-dots-dark': JerseyRedDots,
  'cycling-jersey-white-dark': JerseyWhite,
  'cycling-jersey-yellow-dark': JerseyYellow
}

export type IconNameCyclingWeather = keyof typeof iconsCyclingWeather;

export const iconsCyclingWeather = {
  'cycling-cloudy': Cloudy,
  'cycling-dry': Dry,
  'cycling-extreme': Extreme,
  'cycling-fog': Fog,
  'cycling-hail': Hail,
  'cycling-rain': Rain,
  'cycling-snow': Snow,
  'cycling-sun': Sun,
  'cycling-wind': Wind
}

export type IconNameCycling = keyof typeof iconsCycling;

export const iconsCycling = {
  'cycling-distance': Distance
}
