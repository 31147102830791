import Archery from './icons/os-2024-archery.svg'
import Athletics from './icons/os-2024-athletics.svg'
import Badminton from './icons/os-2024-badminton.svg'
import Basketball from './icons/os-2024-basketball.svg'
import Basketball3x3 from './icons/os-2024-basketball3x3.svg'
import BeachVolley from './icons/os-2024-beach-volley.svg'
import Belg from './icons/os-2024-belg.svg'
import BmxFreestyle from './icons/os-2024-bmx-freestyle.svg'
import BmxRacing from './icons/os-2024-bmx-racing.svg'
import Boxing from './icons/os-2024-boxing.svg'
import Breakdance from './icons/os-2024-breakdance.svg'
import CanoeSlalom from './icons/os-2024-canoe-slalom.svg'
import CanoeSprint from './icons/os-2024-canoe-sprint.svg'
import Climbing from './icons/os-2024-climbing.svg'
import Diving from './icons/os-2024-diving.svg'
import EquestrianDressage from './icons/os-2024-equestrian-dressage.svg'
import EquestrianEventing from './icons/os-2024-equestrian-eventing.svg'
import EquestrianJumping from './icons/os-2024-equestrian-jumping.svg'
import Fencing from './icons/os-2024-fencing.svg'
import Golf from './icons/os-2024-golf.svg'
import GymnasticsArtistic from './icons/os-2024-gymnastics-artistic.svg'
import GymnasticsRhythmic from './icons/os-2024-gymnastics-rhytmic.svg'
import Handball from './icons/os-2024-handball.svg'
import Hockey from './icons/os-2024-hockey.svg'
import Judo from './icons/os-2024-judo.svg'
import Mountainbike from './icons/os-2024-mountainbike.svg'
import Pentathlon from './icons/os-2024-pentathlon.svg'
import RoadCycling from './icons/os-2024-road-cycling.svg'
import Rowing from './icons/os-2024-rowing.svg'
import Rugby from './icons/os-2024-rugby.svg'
import Sailing from './icons/os-2024-sailing.svg'
import Shooting from './icons/os-2024-shooting.svg'
import Skateboarding from './icons/os-2024-skateboarding.svg'
import Soccer from './icons/os-2024-soccer.svg'
import Surfing from './icons/os-2024-surfing.svg'
import Swimming from './icons/os-2024-swimming.svg'
import SwimmingArtistic from './icons/os-2024-swimming-artistic.svg'
import SwimmingMarathon from './icons/os-2024-swimming-marathon.svg'
import TableTennis from './icons/os-2024-table-tennis.svg'
import Taekwondo from './icons/os-2024-taekwondo.svg'
import Tennis from './icons/os-2024-tennis.svg'
import TrackCycling from './icons/os-2024-track-cycling.svg'
import Trampoline from './icons/os-2024-trampoline.svg'
import Triathlon from './icons/os-2024-triathlon.svg'
import Volleyball from './icons/os-2024-volleyball.svg'
import Waterpolo from './icons/os-2024-waterpolo.svg'
import Weightlifting from './icons/os-2024-weightlifting.svg'
import Wrestling from './icons/os-2024-wrestling.svg'

export type IconNameOS2024 = keyof typeof iconsOS2024

export const iconsOS2024 = {
  'os-2024-archery': Archery,
  'os-2024-athletics': Athletics,
  'os-2024-badminton': Badminton,
  'os-2024-basketball': Basketball,
  'os-2024-basketball3x3': Basketball3x3,
  'os-2024-beach-volley': BeachVolley,
  'os-2024-belg': Belg,
  'os-2024-bmx-freestyle': BmxFreestyle,
  'os-2024-bmx-racing': BmxRacing,
  'os-2024-boxing': Boxing,
  'os-2024-breakdance': Breakdance,
  'os-2024-canoe-slalom': CanoeSlalom,
  'os-2024-canoe-sprint': CanoeSprint,
  'os-2024-climbing': Climbing,
  'os-2024-diving': Diving,
  'os-2024-equestrian-dressage': EquestrianDressage,
  'os-2024-equestrian-eventing': EquestrianEventing,
  'os-2024-equestrian-jumping': EquestrianJumping,
  'os-2024-fencing': Fencing,
  'os-2024-golf': Golf,
  'os-2024-gymnastics-artistic': GymnasticsArtistic,
  'os-2024-gymnastics-rhythmic': GymnasticsRhythmic,
  'os-2024-handball': Handball,
  'os-2024-hockey': Hockey,
  'os-2024-judo': Judo,
  'os-2024-mountainbike': Mountainbike,
  'os-2024-pentathlon': Pentathlon,
  'os-2024-road-cycling': RoadCycling,
  'os-2024-rowing': Rowing,
  'os-2024-rugby': Rugby,
  'os-2024-sailing': Sailing,
  'os-2024-shooting': Shooting,
  'os-2024-skateboarding': Skateboarding,
  'os-2024-soccer': Soccer,
  'os-2024-surfing': Surfing,
  'os-2024-swimming': Swimming,
  'os-2024-swimming-artistic': SwimmingArtistic,
  'os-2024-swimming-marathon': SwimmingMarathon,
  'os-2024-table-tennis': TableTennis,
  'os-2024-taekwondo': Taekwondo,
  'os-2024-tennis': Tennis,
  'os-2024-track-cycling': TrackCycling,
  'os-2024-trampoline': Trampoline,
  'os-2024-triathlon': Triathlon,
  'os-2024-volleyball': Volleyball,
  'os-2024-waterpolo': Waterpolo,
  'os-2024-weightlifting': Weightlifting,
  'os-2024-wrestling': Wrestling,
}
