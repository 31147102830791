import type { FunctionComponent, SVGProps } from 'react'
import type { IconNameChannels, } from './svgs/channels/icons'
import type { IconNameFormula1 } from './svgs/formula1/icons'
import type { IconNameGeneralActions, IconNameGeneralArrows, IconNameGeneralMeta, IconNameGeneralNavigation,IconNameGeneralStatus } from './svgs/general/icons'
import type { IconNameGeneric } from './svgs/generic/icons'
// import type { IconNameOS2020 } from './svgs/os-2020/icons'
// import type { IconNameOS2022 } from './svgs/os-2022/icons'
import type { IconNameSoccer } from './svgs/soccer/icons'
import type { IconNameSports } from './svgs/sports/icons'
import type { IconNameTennis } from './svgs/tennis/icons'

import { Logos, logos } from '../../atoms/logo/svgs'

import { iconsChannels } from './svgs/channels/icons'
import {
  IconNameCyclingCyclists,
  IconNameCyclingEvents,
  IconNameCyclingJerseys,
  IconNameCyclingWeather
} from './svgs/cycling/icons'
import {
  IconNameCycling,
  iconsCycling,
  iconsCyclingCyclists,
  iconsCyclingEvents,
  iconsCyclingJerseys,
  iconsCyclingWeather
} from './svgs/cycling/icons'
import { iconsFormula1 } from './svgs/formula1/icons'
import { iconsGeneralActions, iconsGeneralArrows, iconsGeneralMeta, iconsGeneralNavigation, iconsGeneralStatus } from './svgs/general/icons'
import { iconsGeneric } from './svgs/generic/icons'
import { IconNameOS2024, iconsOS2024 } from './svgs/os-2024/icons'
// import { iconsOS2020 } from './svgs/os-2020/icons'
// import { iconsOS2022 } from './svgs/os-2022/icons'
import { iconsSoccer } from './svgs/soccer/icons'
import { iconsSports } from './svgs/sports/icons'
import { iconsTennis } from './svgs/tennis/icons'

type Icons = IconNameGeneralActions
  | IconNameGeneralArrows
  | IconNameGeneralStatus
  | IconNameGeneralMeta
  | IconNameGeneralNavigation
  | IconNameGeneric
  | IconNameSports
  | IconNameChannels
  | IconNameCycling
  | IconNameCyclingEvents
  | IconNameCyclingCyclists
  | IconNameCyclingJerseys
  | IconNameCyclingWeather
  | IconNameSoccer
  | IconNameTennis
  | IconNameFormula1
  | Logos
  | IconNameOS2024
  // | IconNameOS2020
  // | IconNameOS2022

type IconCollection = Record<string, FunctionComponent<SVGProps<SVGElement>>>

const icons: IconCollection = {
  ...iconsGeneralActions,
  ...iconsGeneralNavigation,
  ...iconsGeneralArrows,
  ...iconsGeneralMeta,
  ...iconsGeneralStatus,
  ...iconsGeneric,
  ...iconsSports,
  ...iconsChannels,
  ...iconsCycling,
  ...iconsCyclingEvents,
  ...iconsCyclingCyclists,
  ...iconsCyclingJerseys,
  ...iconsCyclingWeather,
  ...iconsSoccer,
  ...iconsTennis,
  ...iconsFormula1,
  ...logos,
  ...iconsOS2024
  // TODO: figure out a way to tree shake unused icons
  // ...iconsOS2020,
  // ...iconsOS2022
}

export type {
  Icons,
  IconCollection
}
export {
  icons,
  iconsGeneralActions,
  iconsGeneralNavigation,
  iconsGeneralArrows,
  iconsGeneralMeta,
  iconsGeneralStatus,
  iconsGeneric,
  iconsSports,
  iconsChannels,
  iconsCyclingEvents,
  iconsCyclingCyclists,
  iconsCyclingJerseys,
  iconsCyclingWeather,
  iconsSoccer,
  iconsTennis,
  iconsFormula1,
  logos,
  iconsOS2024
  // TODO: figure out a way to tree shake unused icons
  // iconsOS2020,
  // iconsOS2022
}
