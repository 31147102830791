import {
  Environment,
  SporzaAEMHost,
  SporzaPublicSiteHost,
  VRTBrandHosts
} from '@sporza/config'

const stripRequestParameters = (string = '') =>
  string.split('?')[0]

const removeTrailingSlash = (string = '') =>
  string.replace(/\/+$/, '')

const addTrailingSlash = (string = '') =>
  string.replace(/\/?$/, '/')

const getPathName = (url?: string | URL | null): string => {
  if (!url) {
    return ''
  }

  if (!(url instanceof URL)) {
    url = new URL(url)
  }

  return url.pathname
}

const getPermalinkId = (url?: string | URL | null): string => {
  const urlWithoutQueryParams = url?.toString().split('?')[0]
  const shouldGetPermalinkId = urlWithoutQueryParams
    && urlWithoutQueryParams.indexOf('~') > 0
    && urlWithoutQueryParams.indexOf('~') !== urlWithoutQueryParams.length - 1
  let permalinkId = shouldGetPermalinkId
    ? urlWithoutQueryParams.substring(urlWithoutQueryParams.lastIndexOf('~') + 1)
    : ''

  permalinkId = permalinkId.replace(/\/$/, '')

  return permalinkId
    ? permalinkId
    : url
      ? url.toString()
      : 'no permalink id found'
}

type AEMEditUrlFunction = (
  config: {
    path?: string,
    environment: Environment
  }
) => string;

const getAEMEditUrl: AEMEditUrlFunction = ({ path, environment }) =>
  path
    ? `https://${SporzaAEMHost[environment]}/editor.html/content/sporza${removeTrailingSlash(path)}.html`
    : `https://${SporzaAEMHost[environment]}/editor.html/content/sporza/nl.html`

const isAEMSiteDomain = (url: string | URL = ''): boolean => {
  const parsedUrl = new URL(url)

  return Object
    .values(SporzaAEMHost)
    .includes(parsedUrl.hostname as SporzaAEMHost)
}

const isSporzaPublicSiteDomain = (url: string | URL = ''): boolean => {
  const parsedUrl = new URL(url)

  return Object
    .values(SporzaPublicSiteHost)
    .includes(parsedUrl.hostname as SporzaPublicSiteHost)
}

const isExternalVRTUrl = (url: string | URL): string | undefined => {
  try {
    if (!url) {
      return
    }

    if (!(url instanceof URL)) {
      url = new URL(url)
    }

    const { hostname } = url

    const isVRTBrandHosts = Object
      .values(VRTBrandHosts)
      .find(brandHost =>
        hostname.indexOf(brandHost) > -1
      )

    return isVRTBrandHosts
      && Object
        .keys(VRTBrandHosts)
        [Object.values(VRTBrandHosts).indexOf(isVRTBrandHosts)]
  } catch (e) {
    console.debug(`could not determine hostname of ${url}`)

    return
  }
}


const searchParams = (typeof window !== 'undefined' && 'URLSearchParams' in window) && new URLSearchParams(window.location.search)

const setParam = (label: string, value: string, hard = false) => {
  if (searchParams) {
    if (!value) {
      searchParams.delete(label)
    } else {
      searchParams.set(label, value)
    }

    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    if (hard) {
      window.location.href = newRelativePathQuery
    } else {
      history.replaceState({}, '', newRelativePathQuery)
    }
  }
}

const getParam = (label: string) => {
  if (searchParams)
    return searchParams.get(label)
}

const decodeQueryStringValue = (value: string) => {
  // Replace + with space
  value = value.replace(/\+/g, ' ')

  // Decode the query string value
  return decodeURIComponent(value)
}

const addProtocolToUrl = (url: string, protocol = 'https'): string => {
  if (/^(\w+:)?\/\//.test(url)) {
    return url
  }

  return `${protocol}://${url}`
}

const isSamePath = (url1?: string | URL, url2?: string | URL): boolean => {
  if (!url1 || !url2) {
    return false
  }

  const path1 = new URL(url1, 'https://dummy').pathname
  const path2 = new URL(url2, 'https://dummy').pathname

  return path1 === path2
}

export {
  getPathName,
  getPermalinkId,
  getAEMEditUrl,
  isAEMSiteDomain,
  isSporzaPublicSiteDomain,
  isExternalVRTUrl,
  addTrailingSlash,
  getParam,
  setParam,
  decodeQueryStringValue,
  addProtocolToUrl,
  isSamePath,
  removeTrailingSlash,
  stripRequestParameters
}
