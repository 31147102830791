import Aanbod from './icons/aanbod.svg'
import Account from './icons/account.svg'
import AccountSettings from './icons/account-settings.svg'
import AccountSettingsSolid from './icons/account-settings-solid.svg'
import AccountSolid from './icons/account-solid.svg'
import Add from './icons/add.svg'
import AddAlt from './icons/add-alt.svg'
import Airplay from './icons/airplay.svg'
import ArrowCircleLeft from './icons/arrow-circle-left.svg'
import ArrowCircleLeftDark from './icons/arrow-circle-left-dark.svg'
import ArrowCircleRight from './icons/arrow-circle-right.svg'
import ArrowCircleRightDark from './icons/arrow-circle-right-dark.svg'
import ArrowClockwise from './icons/arrow-clockwise.svg'
import ArrowDown from './icons/arrow-down.svg'
import ArrowLeft from './icons/arrow-left.svg'
import ArrowRight from './icons/arrow-right.svg'
import ArrowRightThin from './icons/arrow-right-thin.svg'
import ArrowUp from './icons/arrow-up.svg'
import Audio from './icons/audio.svg'
import BracketRoundOf2 from './icons/bracket-round-of-2.svg'
import BracketRoundOf4 from './icons/bracket-round-of-4.svg'
import BracketRoundOf8 from './icons/bracket-round-of-8.svg'
import BracketRoundOf16 from './icons/bracket-round-of-16.svg'
import BracketRoundOf20 from './icons/bracket-round-of-20.svg'
import BracketRoundOf24 from './icons/bracket-round-of-24.svg'
import Calendar from './icons/calendar.svg'
import CaretDoubleDown from './icons/caret-double-down.svg'
import CaretDoubleUp from './icons/caret-double-up.svg'
import CaretDown from './icons/caret-down.svg'
import CaretUp from './icons/caret-up.svg'
import Champion from './icons/champion.svg'
import ChampionSolid from './icons/champion-solid.svg'
import Chart from './icons/chart.svg'
import CheckAlt from './icons/check-alt.svg'
import CheckOff from './icons/check-off.svg'
import CheckOn from './icons/check-on.svg'
import CheckOnSolid from './icons/check-on-solid.svg'
import ChevronDown from './icons/chevron-down.svg'
import ChevronLeft from './icons/chevron-left.svg'
import ChevronLeftDisabled from './icons/chevron-left-disabled.svg'
import ChevronLeftDisabledDark from './icons/chevron-left-disabled-dark.svg'
import ChevronRight from './icons/chevron-right.svg'
import ChevronRightDisabled from './icons/chevron-right-disabled.svg'
import ChevronRightDisabledDark from './icons/chevron-right-disabled-dark.svg'
import ChevronUp from './icons/chevron-up.svg'
import ChevronVrtDown from './icons/chevron-vrt-down.svg'
import Clipboard from './icons/clipboard.svg'
import Clock from './icons/clock.svg'
import ClockWithBackground from './icons/clock-with-background.svg'
import Close from './icons/close.svg'
import ColumnCollapse from './icons/column-collapse.svg'
import ColumnExpand from './icons/column-expand.svg'
import Competitie from './icons/competitie.svg'
import Devices from './icons/devices.svg'
import Distance from './icons/distance.svg'
import Download from './icons/download.svg'
import EMail from './icons/e-mail.svg'
import EMailSolid from './icons/e-mail-solid.svg'
import Error from './icons/error.svg'
import ErrorAlt from './icons/error-alt.svg'
import ErrorSolid from './icons/error-solid.svg'
import Euro2020 from './icons/euro-2020.svg'
import Expand from './icons/expand.svg'
import ExternalLink from './icons/external-link.svg'
import Favorite from './icons/favorite.svg'
import FavoriteSolid from './icons/favorite-solid.svg'
import FavoriteSolidDark from './icons/favorite-solid-dark.svg'
import Filter from './icons/filter.svg'
import FullScreenExpand from './icons/full-screen-expand.svg'
import FullScreenMinimize from './icons/full-screen-minimize.svg'
import Home from './icons/home.svg'
import Info from './icons/info.svg'
import Lightning from './icons/lightning.svg'
import Link from './icons/link.svg'
import Live from './icons/live.svg'
import LiveAlt from './icons/live-alt.svg'
import Location from './icons/location.svg'
import Matchpoint from './icons/matchpoint.svg'
import MatchpointDark from './icons/matchpoint-dark.svg'
import Menu from './icons/menu.svg'
import Microphone from './icons/microphone.svg'
import MicrophoneSolid from './icons/microphone-solid.svg'
import MijnSporza from './icons/mijn-sporza.svg'
import More from './icons/more.svg'
import News from './icons/news.svg'
import Next from './icons/next.svg'
import NoResult from './icons/no-result.svg'
import NotificationOff from './icons/notification-off.svg'
import NotificationOffDark from './icons/notification-off-dark.svg'
import NotificationOn from './icons/notification-on.svg'
import NotificationOnDark from './icons/notification-on-dark.svg'
import NotificationsDisabled from './icons/notifications-disabled.svg'
import NotificationsDisabledDark from './icons/notifications-disabled-dark.svg'
import NotificationsOff from './icons/notifications-off.svg'
import NotificationsOffDark from './icons/notifications-off-dark.svg'
import NotificationsOn from './icons/notifications-on.svg'
import NotificationsOnDark from './icons/notifications-on-dark.svg'
import OlympicRings from './icons/olympic-rings.svg'
import OsFull from './icons/os-full.svg'
import PauseAudio from './icons/pause-audio.svg'
import PauseAudioAlt from './icons/pause-audio-alt.svg'
import PauseVideoSolid from './icons/pause-video-solid.svg'
import Pencil from './icons/pencil.svg'
import PencilSolid from './icons/pencil-solid.svg'
import Pin from './icons/pin.svg'
import PinSolid from './icons/pin-solid.svg'
import PlayAudio from './icons/play-audio.svg'
import PlayAudioAlt from './icons/play-audio-alt.svg'
import PlayVideo from './icons/play-video.svg'
import PlayVideoSolid from './icons/play-video-solid.svg'
import PlaybackSpeed05x from './icons/playback-speed-0.5x.svg'
import PlaybackSpeed08x from './icons/playback-speed-0.8x.svg'
import PlaybackSpeed10x from './icons/playback-speed-1.0x.svg'
import PlaybackSpeed12x from './icons/playback-speed-1.2x.svg'
import PlaybackSpeed15x from './icons/playback-speed-1.5x.svg'
import PlaybackSpeed20x from './icons/playback-speed-2.0x.svg'
import PlaybackSpeed30x from './icons/playback-speed-3.0x.svg'
import Podcasts from './icons/podcasts.svg'
import Previous from './icons/previous.svg'
import Privacy from './icons/privacy.svg'
import Quote from './icons/quote.svg'
import RadioDeselected from './icons/radio-deselected.svg'
import RadioSelected from './icons/radio-selected.svg'
import ReplayAudio from './icons/replay-audio.svg'
import ReplayAudioAlt from './icons/replay-audio-alt.svg'
import Rules from './icons/rules.svg'
import Screen from './icons/screen.svg'
import Screencast from './icons/screencast.svg'
import Search from './icons/search.svg'
import Settings from './icons/settings.svg'
import SettingsSolid from './icons/settings-solid.svg'
import Share from './icons/share.svg'
import ShareAndroid from './icons/share-android.svg'
import ShowLess from './icons/show-less.svg'
import ShowMore from './icons/show-more.svg'
import SkipBackward from './icons/skip-backward.svg'
import SkipForward from './icons/skip-forward.svg'
import SortDown from './icons/sort-down.svg'
import SortUp from './icons/sort-up.svg'
import Spinner from './icons/spinner.svg'
import SpinnerDark from './icons/spinner-dark.svg'
import Stack from './icons/stack.svg'
import Subtract from './icons/subtract.svg'
import Television from './icons/television.svg'
import TheaterModus from './icons/theater-modus.svg'
import Today from './icons/today.svg'
import Transcript from './icons/transcript.svg'
import Trophy from './icons/trophy.svg'
import Typo from './icons/typo.svg'
import Video from './icons/video.svg'
import VideoSolid from './icons/video-solid.svg'
import VideoTimestamp from './icons/video-timestamp.svg'
import VolumeMute from './icons/volume-mute.svg'
import VolumeMuteSolid from './icons/volume-mute-solid.svg'
import VolumeOn from './icons/volume-on.svg'
import VolumeOnSolid from './icons/volume-on-solid.svg'
import VolumePaused from './icons/volume-paused.svg'
import VolumePausedSolid from './icons/volume-paused-solid.svg'

export type IconNameGeneralActions = keyof typeof iconsGeneralActions;

export const iconsGeneralActions = {
  'close': Close,
  'filter': Filter,
  'sort-up': SortUp,
  'sort-down': SortDown,
  'add': Add,
  'add-alt': AddAlt,
  'subtract': Subtract,
  'show-more': ShowMore,
  'show-less': ShowLess,

  'caret-down': CaretDown,
  'caret-double-down': CaretDoubleDown,
  'caret-double-up': CaretDoubleUp,
  'caret-up': CaretUp,

  'pencil': Pencil,
  'pencil-solid': PencilSolid,

  'play-video': PlayVideo,
  'play-video-solid': PlayVideoSolid,
  'pause-video-solid': PauseVideoSolid,

  'full-screen-expand': FullScreenExpand,
  'full-screen-minimize': FullScreenMinimize,

  'volume-on': VolumeOn,
  'volume-on-solid': VolumeOnSolid,
  'volume-mute': VolumeMute,
  'volume-mute-solid': VolumeMuteSolid,
  'volume-paused': VolumePaused,
  'volume-paused-solid': VolumePausedSolid,

  'play-audio': PlayAudio,
  'pause-audio': PauseAudio,
  'replay-audio': ReplayAudio,

  'play-audio-alt': PlayAudioAlt,
  'pause-audio-alt': PauseAudioAlt,
  'replay-audio-alt': ReplayAudioAlt,

  'skip-backward': SkipBackward,
  'skip-forward': SkipForward,

  'previous': Previous,
  'next': Next,

  'playback-speed05x': PlaybackSpeed05x,
  'playback-speed08x': PlaybackSpeed08x,
  'playback-speed10x': PlaybackSpeed10x,
  'playback-speed12x': PlaybackSpeed12x,
  'playback-speed15x': PlaybackSpeed15x,
  'playback-speed20x': PlaybackSpeed20x,
  'playback-speed30x': PlaybackSpeed30x,

  'microphone': Microphone,
  'microphone-solid': MicrophoneSolid,

  'notifications-disabled': NotificationsDisabled,
  'notifications-disabled-dark': NotificationsDisabledDark,
  'notifications-on': NotificationsOn,
  'notifications-on-dark': NotificationsOnDark,
  'notifications-off': NotificationsOff,
  'notifications-off-dark': NotificationsOffDark,

  'notification-on': NotificationOn,
  'notification-on-dark': NotificationOnDark,
  'notification-off': NotificationOff,
  'notification-off-dark': NotificationOffDark,

  'airplay': Airplay,

  'screencast': Screencast,

  'column-expand': ColumnExpand,
  'column-collapse': ColumnCollapse,

  'theater-modus': TheaterModus,

  'expand': Expand,

  'download': Download,

  'clipboard': Clipboard,

  'transcript': Transcript,

  'stack': Stack,
}

export type IconNameGeneralNavigation = keyof typeof iconsGeneralNavigation;

export const iconsGeneralNavigation = {
  'search': Search,
  'no-result': NoResult,

  'calendar': Calendar,
  'today': Today,

  'account': Account,
  'account-solid': AccountSolid,

  'account-settings': AccountSettings,
  'account-settings-solid': AccountSettingsSolid,

  'video': Video,
  'video-solid': VideoSolid,

  'audio': Audio,

  'podcasts': Podcasts,

  'clock': Clock,

  'share': Share,
  'share-android': ShareAndroid,

  'home': Home,

  'settings': Settings,
  'settings-solid': SettingsSolid,

  'pin': Pin,
  'pin-solid': PinSolid,

  'news': News,
  'mijn-sporza': MijnSporza,
  'aanbod': Aanbod,
  'os-full': OsFull,
  'competitie': Competitie,
  'menu': Menu,
  'euro2020': Euro2020,
  'more': More,
  'chart': Chart,
  'olympic-rings': OlympicRings,

  'bracket-round-of-2': BracketRoundOf2,
  'bracket-round-of-4': BracketRoundOf4,
  'bracket-round-of-8': BracketRoundOf8,
  'bracket-round-of-16': BracketRoundOf16,
  'bracket-round-of-20': BracketRoundOf20,
  'bracket-round-of-24': BracketRoundOf24,
}

export type IconNameGeneralArrows = keyof typeof iconsGeneralArrows;

export const iconsGeneralArrows = {
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-circle-left': ArrowCircleLeft,
  'arrow-circle-left-dark': ArrowCircleLeftDark,
  'arrow-circle-right': ArrowCircleRight,
  'arrow-circle-right-dark': ArrowCircleRightDark,
  'arrow-right-thin': ArrowRightThin,
  'arrow-clockwise': ArrowClockwise,

  'chevron-up': ChevronUp,
  'chevron-down': ChevronDown,
  'chevron-right-disabled-dark': ChevronRightDisabledDark,
  'chevron-right': ChevronRight,
  'chevron-right-disabled': ChevronRightDisabled,
  'chevron-left-disabled-dark': ChevronLeftDisabledDark,
  'chevron-left': ChevronLeft,
  'chevron-left-disabled': ChevronLeftDisabled,

  'chevron-vrt-down': ChevronVrtDown,
}

export type IconNameGeneralMeta = keyof typeof iconsGeneralMeta;

export const iconsGeneralMeta = {
  'clock': Clock,
  'clock-with-background': ClockWithBackground,
  'link': Link,
  'e-mail': EMail,
  'e-mail-solid': EMailSolid,
  'champion': Champion,
  'trophy': Trophy,
  'champion-solid': ChampionSolid,
  'lightning': Lightning,
  'matchpoint': Matchpoint,
  'matchpoint-dark': MatchpointDark,
  'external-link': ExternalLink,
  'privacy': Privacy,
  'info': Info,
  'quote': Quote,
  'typo': Typo,
  'screen': Screen,
  'devices': Devices,
  'rules': Rules,
  'distance': Distance,
  'location': Location,
  'video-timestamp': VideoTimestamp,
  'television': Television,
}

export type IconNameGeneralStatus = keyof typeof iconsGeneralStatus;

export const iconsGeneralStatus = {
  'check-on': CheckOn,
  'check-on-solid': CheckOnSolid,
  'check-off': CheckOff,
  'check-alt': CheckAlt,
  'favorite': Favorite,
  'favorite-solid': FavoriteSolid,
  'favorite-solid-dark': FavoriteSolidDark,
  'error': Error,
  'error-alt': ErrorAlt,
  'error-solid': ErrorSolid,
  'live': Live,
  'live-alt': LiveAlt,
  'radio-deselected': RadioDeselected,
  'radio-selected': RadioSelected,
  'spinner': Spinner,
  'spinner-dark': SpinnerDark
}
