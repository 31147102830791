import { QueryClient, useQuery } from '@tanstack/react-query'

const queryClient = new QueryClient()

const fetchData = async (url: string | URL) => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('sporza - useFetch - network response was not ok')
  }

  return response.json()
}

const useFetch = (url: string | URL) => {
  return useQuery(
    {
      queryKey: [url],
      queryFn: () => fetchData(url),
    },
    queryClient
  )
}

export {
  useFetch
}
